const slugGenerator = function (nodeID, caption) {
  let slug = nodeID;

  if (caption === "" || caption == null) {
    return slug;
  } else {
    const captionSplit = caption.split(/ - ([^]+)/);
    const captionTitle = captionSplit[0];

    // captionTitle = captionTitle.substring(0, 25);

    slug = captionTitle.replace(/\s+/g, "-");
    slug = slug.replace(/[^a-zA-Z0-9'_-]/g, "");
    slug = shorten(slug, 30, "-");
    slug = slug.toLowerCase();
  }

  if (slug === "" || slug == null) {
    slug = nodeID;
  }

  return slug;
};

const shorten = function (str, maxLen, separator = " ") {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
};

module.exports.slugGenerator = slugGenerator;
