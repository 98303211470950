import React, { useRef, useState, useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import useWindowDimensions from "../../hooks/use-window-dimensions";
import { slugGenerator } from "../../../utils/slugGenerator.js";
import { getCaptionComponents } from "../../../utils/getCaptionComponents";

import Plus from "../../../images/svg/plus.svg";
import Minus from "../../../images/svg/minus.svg";

const FeedItem = ({
  image,
  caption,
  openOverlay,
  nodeID,
  mobileCaptionOpened,
  mobileCaptionOpen,
  index,
}) => {
  const feedItemRef = useRef(null);
  const postURL = "/" + slugGenerator(nodeID, caption);

  const { windowWidth } = useWindowDimensions();

  const [showMobileCaption, setShowMobileCaption] = useState(mobileCaptionOpen);

  useEffect(() => {
    setShowMobileCaption(mobileCaptionOpen);
  }, [mobileCaptionOpen]);
  //
  // Transform caption
  //

  const captionComponents = getCaptionComponents(caption);

  const isMobile = () => {
    return windowWidth <= 767;
  };

  // console.log("cap", mobileCaptionOpen);

  return (
    <div
      className="feed-item"
      ref={feedItemRef}
      data-index={index}
      id={"feed-item-" + nodeID}
      onClick={() => {
        if (!isMobile()) {
          openOverlay(feedItemRef, image, caption, nodeID);
        }
      }}
      onKeyDown={(e) => {
        if (!isMobile() && e.key === "Enter") {
          openOverlay(feedItemRef, image, caption, nodeID);
        }
      }}
      role="button"
      aria-label="Open post information"
      tabIndex={0}
    >
      <div
        className="image-link-wrapper"
        onClick={() => {
          if (isMobile()) {
            mobileCaptionOpened(nodeID);
          }
        }}
        onKeyDown={(e) => {
          if (isMobile() && e.key === "Enter") {
            mobileCaptionOpened(nodeID);
          }
        }}
        role="button"
        aria-label="Open post information"
        tabIndex={0}
      >
        {showMobileCaption ? (
          <Link className="image-link" to={postURL}></Link>
        ) : null}

        <GatsbyImage
          image={getImage(image)}
          alt=""
          className="feed-image-wrapper"
          imgClassName="feed-image"
        />
      </div>
      {isMobile() && showMobileCaption && (
        <div
          className="mobile-caption"
          id={"mobile-caption-" + nodeID}
          data-index={index}
        >
          <div className="top">
            <h1 className="caption-title">
              <Link to={postURL} className="link">
                {captionComponents.title}
              </Link>
            </h1>
            <div className="buttons">
              <div
                className="control"
                onClick={() => mobileCaptionOpened(-1)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    mobileCaptionOpened(-1);
                  }
                }}
                role="button"
                aria-label="Close post information"
                tabIndex={0}
              >
                <span>
                  <Minus />
                </span>
              </div>
              <Link to={postURL}>
                <div className="control">
                  <span>
                    <Plus />
                  </span>
                </div>
              </Link>
            </div>
          </div>
          {showMobileCaption && (
            <div className="bottom">
              {captionComponents.body.details.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FeedItem;
