const { isNotUndefined, trimEndNonAlphanumeric } = require("./utils");

const getCaptionComponents = function (caption) {
  const captionComponents = {
    title: "",
    body: {
      details: [],
      credits: [],
    },
    tags: [],
  };

  if (caption === "" || caption == null) {
    return captionComponents;
  } else {
    const captionSplitOnFirstHyphen = splitStringOnFirstHyphen(caption);
    captionComponents.title = getCaptionTitle(captionSplitOnFirstHyphen);
    captionComponents.body = getCaptionBody(captionSplitOnFirstHyphen);
    captionComponents.tags = getCaptionTags(captionComponents.body.credits);
  }

  return captionComponents;
};

const splitStringOnFirstHyphen = function (string) {
  return string.split(/ - ([^]+)/);
};

const getCaptionTitle = function (captionSplitOnFirstHyphen) {
  return captionSplitOnFirstHyphen[0];
};

const getCaptionBody = function (captionSplitOnFirstHyphen) {
  const captionBody = {
    details: [],
    credits: [],
  };

  if (isNotUndefined(captionSplitOnFirstHyphen[1])) {
    captionBody.details = captionSplitOnFirstHyphen[1].split("\n\n");
    if (captionBody.details.length > 1) {
      captionBody.credits = captionBody.details.pop().split(/\s*(@\S+)\s*/);
    }
  }
  return captionBody;
};

const getCaptionTags = function (captionCredits) {
  const captionTags = [];
  if (isNotUndefined(captionCredits)) {
    captionCredits.forEach((string) => {
      if (string.charAt(0) === "@") {
        string = trimEndNonAlphanumeric(string);
        captionTags.push(string);
      }
    });
  }
  return captionTags;
};

const getTagsRegex = function (caption) {
  const tags = getCaptionComponents(caption).tags;
  let tagsRegex = "/";

  if (tags.length === 0) {
    //will never match
    return "/(?=a)[^a]/";
  } else if (tags.length === 1) {
    tagsRegex = tagsRegex + tags[0] + "/";
  } else {
    for (let i = 0; i < tags.length; i++) {
      if (i === 0) {
        tagsRegex = tagsRegex + tags[i];
      } else {
        tagsRegex = tagsRegex + "|" + tags[i];
      }
    }
    tagsRegex = tagsRegex + "/";
  }

  tagsRegex = tagsRegex.replace(".", "[.]");
  return tagsRegex;
};

module.exports = { getCaptionComponents, getTagsRegex };
