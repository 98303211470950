import React from "react";
import ConditionalWrapper from "../../helpers/conditional-wrapper";

const Divider = ({ padded, pullTop }) => {
  let className = "divider";

  if (pullTop) {
    className = className + " pull-top";
  }

  return (
    <ConditionalWrapper
      condition={padded}
      wrapper={(children) => <div className="padded">{children}</div>}
    >
      <hr className={className} />
    </ConditionalWrapper>
  );
};

export default Divider;
