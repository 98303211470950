import React from "react";
import FeedItem from "./feed-item/feed-item";

const Hit = ({
  hit,
  openOverlay,
  mobileCaptionOpened,
  mobileCaptionOpenNodeID,
  index,
}) => (
  <FeedItem
    image={hit.localFile}
    openOverlay={openOverlay}
    caption={hit.caption}
    nodeID={hit.objectID}
    key={hit.objectID}
    mobileCaptionOpened={mobileCaptionOpened}
    mobileCaptionOpen={mobileCaptionOpenNodeID === hit.objectID ? true : false}
    index={index}
  />
);

export default Hit;
