import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { slugGenerator } from "../../../utils/slugGenerator.js";
import { getCaptionComponents } from "../../../utils/getCaptionComponents.js";

import Plus from "../../../images/svg/plus.svg";
import Minus from "../../../images/svg/minus.svg";

const ItemDescription = ({
  close,
  open,
  replace,
  visible,
  image,
  caption,
  height,
  top,
  nodeID,
}) => {
  const captionComponents = getCaptionComponents(caption);

  const postURL = "/" + slugGenerator(nodeID, caption);

  //const description = captionSplitter(nodeID, caption);
  // if (description.length > 0) {
  //   linkURL = "/" + createPathnameFromTitle(description[0][0]);
  // }
  return (
    <AnimatePresence
      onExitComplete={() => {
        if (replace) {
          open();
        }
      }}
    >
      {visible && (
        <motion.div
          className="overlay"
          style={{ top: top, height: height }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div
            className="overlay-image-wrapper"
            style={{ width: height, height: height }}
          >
            <Link to={postURL}>
              <GatsbyImage
                image={getImage(image)}
                alt=""
                className="overlay-image-wrapper"
                imgClassName="overlay-image"
              />
            </Link>
          </div>
          <div
            className="overlay-description-wrapper"
            style={{ marginLeft: height + 15 }}
          >
            <h1 className="overlay-title">
              <Link to={postURL} className="link">
                {captionComponents.title}
              </Link>
              <span id="overlay-controls">
                <div
                  className="overlay-control"
                  onClick={close}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      close();
                    }
                  }}
                  role="button"
                  aria-label="Close post information"
                  tabIndex={0}
                >
                  <Minus />
                </div>
                <div className="overlay-control">
                  <Link to={postURL}>
                    <Plus />
                  </Link>
                </div>
              </span>
            </h1>
            <div className="overlay-description">
              <div className="description-left">
                {captionComponents.body.details.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
              <div className="description-right">
                <p>
                  {captionComponents.body.credits.map((string, index) => {
                    if (string.charAt(0) === "@") {
                      return (
                        <span className="highlight" key={index}>
                          {" "}
                          {string}{" "}
                        </span>
                      );
                    }
                    return string;
                  })}
                </p>
              </div>
            </div>
            <div className="overlay-fader"></div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ItemDescription;
